<template>
  <div class="app-page md:h-auto md:py-12 md:mx-4 md:my-8 text-center">
    <!--0 review-->
    <article v-if="isShow.review" class="h-inherit pt-20 md:pt-0 audit _review md:bg-white">
      <div class="audit-wrap">
        <section class="audit-ill">
          <h2 class="audit-tit">審核等待中</h2>
          <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
        </section>
        <section class="audit-con">
          <p>
            您的註冊資料已送出，<br />
            請耐心等待業務人員進行審核，感謝
          </p>
        </section>
       
      </div>
    </article>
   

    <!--1 Approval-->
    <article v-if="isShow.approval" class="h-inherit pt-20 md:pt-0 audit _approval md:bg-white">
      <div class="audit-wrap">
        <section class="audit-ill">
          <h2 class="audit-tit">審核通過！</h2>
          <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
        </section>
        <section class="audit-con">
          <p>您的註冊資料已通過，<br />恭喜成為揪美美合作團購主</p>
        </section>
      </div>
    </article>

    <!--fail-->
    <article v-if="isShow.fail" class="h-inherit pt-20 md:pt-0 audit _fail md:bg-white">
      <div class="audit-wrap">
        <section class="audit-ill">
          <h2 class="audit-tit">審核未通過</h2>
          <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
        </section>
        <section class="audit-con">
          <p>您尚未審核通過，<br />業務人員正加緊處理中，感謝</p>
        </section>
        <article>             
          審查失敗原因: {{ user.reason }}
        </article>
      </div>
    </article>

    <!--Abnormal-->
    <article v-if="isShow.abnormal" class="h-inherit pt-20 md:pt-0 audit _abnormal md:bg-white">
      <div class="audit-wrap">
        <section class="audit-ill">
          <h2 class="audit-tit">審核異常</h2>
          <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
        </section>
        <section class="audit-con">
          <p>您個人資料審核異常，<br />請等待業務人員與您聯繫，感謝</p>
        </section>
    
      </div>
    </article>

    <router-link :to="{ name: 'home' }" class="btn-forms py-2 px-24 hidden md:inline-block">
      回首頁
    </router-link>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'status',
  data() {
    return {
      auditPage: ['review', 'approval', 'fail', 'abnormal'],
      isShow: {
        review: false, //等待審核 只能有個人資料跟登出,要防禦path只能到status
        approval: false, //已通過 成功--顯示審核成功顯一次就好 > 3秒 > 導到/dashboard
        fail: false, //退件 等原來的畫面
        abnormal: false //不正常 個人修改頁面?
      }
    }
  },
  created() {
    this.handleShow()
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['getUser']),
    handleShow() {
      if (Object.keys(this.user).length === 0) return
      let key = this.auditPage[this.user.status]
      this.isShow[key] = true
    },
    handleRedirect() {
      //如果有人網址打status判斷1就
      if (this.user.status == 1) {
        this.$router.push('/')
      }
      //如果有人網址打status判斷1就
      else if (this.user.status == -1) {
        this.$router.push({ name:"personal", query:{desc:1} })
      }
    }
  },
  watch: {
    user: {
      deep: true,
      handler(value, oldValue) {
        if (value.id !== oldValue.id) {
          this.handleShow()
        }
      }
    }
  },
  async mounted(){
    await this.getUser()
    await setTimeout(() => {  this.handleRedirect() }, 3000);
  }
}
</script>
